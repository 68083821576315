import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import BreadCrumb from '@components/breadcrumb';
import News from '@views/news';

const RenderBody = ({ doc }) => {
  const news = doc.news;

  return (
    <>
      <SEO
        title="News"
        uri="news"
        desc="Keep up with current Dynamo and fencing news."
      />
      <BreadCrumb name="News" />
      <News data={news} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allNewss.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody doc={doc.node} />;
};

export const query = graphql`
  {
    prismic {
      allNewss {
        edges {
          node {
            news {
              image
              text
              title
            }
          }
        }
      }
    }
  }
`;
