import React from 'react';
import { RichText } from 'prismic-reactjs';

// styles
import { Container, NormalText } from '@styles';
import { NewsSection } from './styles';
import { Row, Title } from '../coaches/styles';
import { LeftCol, RightCol } from '../faq/styles';

const News = ({ data }) => {
  return (
    <>
      {data.map((reference, i) => (
        <NewsSection key={i}>
          <Container normal>
            <Row>
              <LeftCol>
                <img
                  src={reference.image && reference.image.url}
                  alt={reference.image && reference.image.alt}
                />
              </LeftCol>
              <RightCol>
                <RichText render={reference.title} Component={Title} />
                <RichText render={reference.text} Component={NormalText} />
              </RightCol>
            </Row>
          </Container>
        </NewsSection>
      ))}
    </>
  );
};

export default News;
